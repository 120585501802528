* {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: inherit;
}

textarea {
  width: 100%;
  height: 120px;
  resize: none;
  padding: 12px;
  padding-right: 44px;
  font-size: 16px;
  border-radius: 8px;
  font-family: Public Sans, sans-serif;
  border-color: #e8ecee;
  outline-color: #e8ecee;
}

textarea:disabled {
  width: 100%;
  height: 120px;
  resize: none;
  padding: 12px;
  padding-right: 44px;
  font-size: 16px;
  border-radius: 8px;
  font-family: Public Sans, sans-serif;
  border-color: #BDBDBD;
  outline-color: #BDBDBD;
  background-color: white;
  color: #A6B1BC;
}

textarea:focus {
  border-color: #129747;
  outline-color: #129747;
}

/* textarea:active {
  border-color: #129747;
  outline-color: #129747;
} */

textarea:hover:not(:focus):not(:disabled) {
  border-color: #000000;
  outline-color: #000000;
}

textarea.error {
  border-color: #d32f2f;
  outline-color: #d32f2f;
}

.text-editor-css {
  width: 100% !important;
  height: 120px !important;
  resize: none !important;
  padding-left: 12px !important;
  padding-right: 44px !important;
  font-size: 16px !important;
  border: 1px solid #e8ecee;
  border-radius: 8px !important;
  font-family: Public Sans, sans-serif !important;
  line-height: 1.2rem !important;
}

.public-DraftStyleDefault-block {
  margin: 0.2rem 0 !important;
}

.text-editor-css:focus {
  outline: 1px solid #129747;
  border-color: #129747;
  outline-color: #129747;
}

.text-editor-css:active {
  outline: 1px solid #129747;
  border-color: #129747;
  outline-color: #129747;
}

.text-editor-css:hover {
  border-color: #000000;
  outline-color: #000000;
}

.text-editor-css.error {
  border-color: #d32f2f;
  outline-color: #d32f2f;
}

.text-editor-css-disable {
  width: 100% !important;
  height: 120px !important;
  resize: none !important;
  padding-left: 12px !important;
  padding-right: 44px !important;
  font-size: 16px !important;
  border: 1px solid #BDBDBD;
  border-radius: 8px !important;
  font-family: Public Sans, sans-serif !important;
  line-height: 1.2rem !important;
  outline-color: #BDBDBD;
  background-color: white;
  color: #A6B1BC;
}